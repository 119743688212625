<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        md="2"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="getOffer.offerImageList && getOffer.offerImageList.length > 0"
            :src="getOffer.offerImageList[0].url"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <b-avatar
            v-else
            :variant="`light-primary`"
            :text="avatarText(getOffer.titleRu)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="">
                {{ getOffer.titleRu }}
              </h4>
              <b-badge
                v-if="getOffer.offerState"
                :variant="statusVariant(getOffer.offerState).color"
              >
                {{ statusVariant(getOffer.offerState).text }}
              </b-badge>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                @click.prevent="deleteUser"
                variant="outline-danger"
                class=""
              >
                Удалить
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">ID:</span>
            </th>
            <td class="pb-50">
              {{ getOffer.id }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Название:</span>
            </th>
            <td class="pb-50">
              {{ getOffer.titleRu }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Скидка:</span>
            </th>
            <td class="pb-50">{{ getOffer.discount }}%</td>
          </tr>

          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата окончания:</span>
            </th>
            <td class="pb-50">
              {{ getOffer.finishDate | formatDate }}
            </td>
          </tr>
        </table>
      </b-col>

      <b-col>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата создания:</span>
            </th>
            <td class="pb-50">
              {{ getOffer.dateCreated | formatDate }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Дата редактирования:</span>
            </th>
            <td class="pb-50">
              {{ getOffer.dateModified | formatDateHours }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Осталось:</span>
            </th>
            <td class="pb-50">{{ getOffer.daysCountTillTheEnd }} дней</td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Мерчант:</span>
            </th>
            <td class="pb-50" v-if="getOffer">
              <router-link
                :to="{
                  name: 'merchant-view',
                  params: { id: getOffer.vendor.id },
                }"
              >
                <span class="text-nowrap font-weight-bold text-nowrap">
                  {{ getOffer.vendor.name }}
                </span>
              </router-link>
              (id: {{ getOffer.vendor.id }})
            </td>
          </tr>
        </table>
      </b-col>
      <b-col>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Видимый/Скрытый:</span>
            </th>
            <td class="pb-50">
              <span v-if="getOffer.isVisible === 1"> Видимый </span>
              <span v-else> Скрытый </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Подарочный/Неподарочный:</span>
            </th>
            <td class="pb-50">
              <span v-if="getOffer.isGiftable === 1"> Подарочный </span>
              <span v-else> Неподарочный </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Статус:</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                v-if="getOffer.offerState"
                :variant="statusVariant(getOffer.offerState).color"
              >
                {{ statusVariant(getOffer.offerState).text }}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Тип:</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge v-if="getOffer.offerType" variant="light-primary">
                {{ typeVariant(getOffer.offerType).title }}
              </b-badge>
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BBadge } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios.js";

export default {
  components: {
    BBadge,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["getOffer"]),
  },
  setup() {
    return {
      avatarText,
    };
  },
  data() {
    return {};
  },
  methods: {
    statusVariant(status) {
      if (status === "ACTIVE")
        return {
          color: "light-success",
          text: "Активный",
        };
      if (status === "DELETED")
        return {
          color: "light-danger",
          text: "Удаленный",
        };
      if (status === "BLOCKED")
        return {
          color: "light-warning",
          text: "Заблокированный",
        };
      if (status === "PENDING")
        return {
          color: "light-secondary",
          text: "В ожидании",
        };
      return {
        color: "light-primary",
        text: "Непонятно",
      };
    },
    typeVariant(type) {
      if (type === "CERTIFICATE")
        return {
          title: "Сертификат",
          value: "CERTIFICATE",
        };
      if (type === "INFORMATION")
        return {
          title: "Информационное",
          value: "INFORMATION",
        };
      if (type === "VAUCHER")
        return {
          title: "Ваучер",
          value: "VAUCHER",
        };
      if (type === "SPECIAL_CASHBACK")
        return {
          title: "Спец. кэшбэк",
          value: "SPECIAL_CASHBACK",
        };
      return {
        color: "light-primary",
        text: "Непонятно",
      };
    },
    deleteUser() {
      this.$bvModal
        .msgBoxConfirm("Вы уверены что хотите удалить?", {
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          okTitle: "Удалить",
          cancelTitle: "Отмена",
        })
        .then((value) => {
          const numberArray = [];
          numberArray.push(Number(this.$router.currentRoute.params.id));
          if (value === true) {
            axiosIns
              .delete("offer/deleteOffers", {
                data: {
                  offerIdList: numberArray,
                },
              })
              .then((response) => {
                if (response.status == 200) {
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: `Успешно`,
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: `Пользователь был успешно удален!`,
                      },
                    },
                    {
                      position: "top-right",
                    }
                  );
                  this.$store.dispatch(
                    "fetchOffer",
                    this.$router.currentRoute.params.id
                  );
                }
              })
              .catch((error) => {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Ошибка`,
                      icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                      variant: "danger",
                      text: error.response.data.errorMessage,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
                if (error.response) {
                  // Request made and server responded
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log("Error", error.message);
                }
              });
          }
        });
    },
  },
};
</script>

<style></style>
