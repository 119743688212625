<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <div>
          <h5>Изображения</h5>
          <VueFileAgent
            ref="vueFileAgent"
            :multiple="true"
            :sortable="false"
            :deletable="true"
            :accept="'image, .jpg, .png, .jpeg, .tiff'"
            :meta="false"
            :maxSize="'5MB'"
            :maxFiles="14"
            :helpText="'Выберите изображения'"
            :errorText="{
              type: 'Недопустимый тип файла. Допустимы только изображения',
              size: 'Изображения не должны весить больше 5мб',
            }"
            @select="filesSelected($event)"
            @beforedelete="onBeforeDelete($event)"
            @delete="fileDeleted($event)"
            v-model="getOffer.filteredOfferImages"
          >
          </VueFileAgent>
          <span class="sm-size"
            >(Поддерживаются файлы в формате .jpg, .png, .tiff)
          </span>
        </div>
      </template>
    </b-media>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название Ru"
              rules="required"
            >
              <b-form-group label="Название Ru" label-for="titleRu">
                <b-form-input
                  id="titleRu"
                  :state="errors.length > 0 ? false : null"
                  v-model="getOffer.titleRu"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название Uz"
              rules="required"
            >
              <b-form-group label="Название Uz" label-for="titleUz">
                <b-form-input
                  id="titleUz"
                  :state="errors.length > 0 ? false : null"
                  v-model="getOffer.titleUz"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: Full Name -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Название En"
              rules="required"
            >
              <b-form-group label="Название En" label-for="titleEn">
                <b-form-input
                  id="titleEn"
                  :state="errors.length > 0 ? false : null"
                  v-model="getOffer.titleEn"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Category -->
          <!-- <b-col cols="12" md="4">
          <b-form-group label="Категория" label-for="merchant-category">
            <v-select
              v-model="getOffer.vendorCategoryList"
              :options="getCategories.categories"
              @input="changeCategoryList"
              label="titleRu"
              input-id="merchant-category"
              placeholder="Выберите категории"
              multiple
            />
          </b-form-group>
        </b-col> -->

          <!-- Field: Status -->
          <!-- <b-col cols="12" md="4">
          <b-form-group label="Status" label-for="merchant-status">
            <v-select
              v-model="getOffer.vendorState"
              :options="statusOptions"
              :clearable="false"
              input-id="merchant-status"
            />
          </b-form-group>
        </b-col>
 -->
          <!-- Field: Description RU -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание RU"
              rules="required"
            >
              <b-form-group label="Описание RU" label-for="merch-desc-ru">
                <b-form-textarea
                  id="merch-desc-ru"
                  v-model="getOffer.descriptionRu"
                  placeholder="Описание"
                  :state="errors.length > 0 ? false : null"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Description UZ -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание UZ"
              rules="required"
            >
              <b-form-group label="Описание UZ" label-for="merch-desc-uz">
                <b-form-textarea
                  id="merch-desc-uz"
                  v-model="getOffer.descriptionUz"
                  placeholder="Описание"
                  :state="errors.length > 0 ? false : null"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Description EN -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Описание EN"
              rules="required"
            >
              <b-form-group label="Описание EN" label-for="merch-desc-en">
                <b-form-textarea
                  id="merch-desc-en"
                  v-model="getOffer.descriptionEn"
                  placeholder="Описание"
                  :state="errors.length > 0 ? false : null"
                  rows="7"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Discount -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Скидка"
              rules="required"
            >
              <b-form-group label="Скидка" label-for="discount">
                <b-form-input
                  id="discount"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  @input="checkDiscount"
                  v-mask="'###'"
                  v-model="getOffer.discount"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Discount -->
          <b-col cols="12" md="4" v-if="getOffer.finishDate">
            <validation-provider
              #default="{ errors }"
              name="Дата окончания"
              rules="required"
            >
              <b-form-group label="Дата окончания" label-for="dateExpired">
                <flat-pickr
                  v-model="getOffer.finishDate"
                  id="new-offer-expired-date"
                  placeholder="Выберите дату окончания"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                  :config="datePickerConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: offerState -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Статус"
              rules="required"
            >
              <b-form-group label="Статус" label-for="offerState">
                <v-select
                  id="offerState"
                  placeholder="Выберите статус предложения"
                  v-model="getOffer.offerState"
                  :options="statusOptions"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: offerType -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Тип"
              rules="required"
            >
              <b-form-group label="Тип" label-for="offerType">
                <v-select
                  id="offerType"
                  placeholder="Выберите тип статуса"
                  v-model="getOffer.offerType"
                  :options="offerTypes2"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: offerMerchant -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Мерчант"
              rules="required"
            >
              <b-form-group label="Мерчант" label-for="offerMerchant">
                <v-select
                  id="offerMerchant"
                  placeholder="Выберите мерчант предложения"
                  v-model="getOffer.vendor"
                  :options="sortedMerchants"
                  @search="getMerchants"
                  @input="getBranches"
                  label="name"
                  :state="errors.length > 0 ? false : null"
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: offerBranches -->
          <b-col cols="12" md="4">
            <validation-provider
              #default="{ errors }"
              name="Филиалы"
              rules="required"
            >
              <b-form-group label="Филиалы" label-for="offerBranches">
                <v-select
                  id="offerBranches"
                  placeholder="Выберите филиал предложения"
                  v-model="getOffer.branches"
                  label="name"
                  :options="sortedBranches"
                  :state="errors.length > 0 ? false : null"
                  multiple
                >
                  <template #no-options="{ search, searching, loading }">
                    Пусто
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Тип -->
          <!--  <b-col cols="12" md="4">
          <b-form-group label="Тип" label-for="offerType">
            <v-select
              id="offerType"
              placeholder="Выберите тип предложения"
              v-model="getOffer.offerState"
              :options="offerTypes"
            />
          </b-form-group>
        </b-col> -->

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";

export default {
  components: {
    flatPickr,
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters(["getOffer", "getOfferImages"]),
    typeVariant() {
      const typeVariants = {
        /* eslint-disable key-spacing */
        CERTIFICATE: {
          title: "Сертификат",
          value: "CERTIFICATE",
        },
        INFORMATION: {
          title: "Информационное",
          value: "INFORMATION",
        },
        VAUCHER: {
          title: "Ваучер",
          value: "VAUCHER",
        },
        SPECIAL_CASHBACK: {
          title: "Спец. кэшбэк",
          value: "SPECIAL_CASHBACK",
        },
        /* eslint-enable key-spacing */
      };

      return (type) => typeVariants[type];
    },
  },
  watch: {
    getOffer() {
      this.getBranches();
    },
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,

      getBranchesFirstTime: false,
      file: null,
      fileUrl: "",
      categories: null,
      logo: null,
      sortedBranches: [],
      sortedMerchants: [],
      selectedMerchant: null,
      selectedBranches: null,
      statusOptions: ["ACTIVE", "DELETED", "BLOCKED", "SUSPENDED"],
      offerTypes2: [
        "CERTIFICATE",
        "INFORMATION",
        "VAUCHER",
        "SPECIAL_CASHBACK",
      ],
      offerTypes: [
        {
          title: "Сертификат",
          value: "CERTIFICATE",
        },
        {
          title: "Информационное",
          value: "INFORMATION",
        },
        {
          title: "Ваучер",
          value: "VAUCHER",
        },
        {
          title: "Спец. кэшбэк",
          value: "SPECIAL_CASHBACK",
        },
      ],
      datePickerConfig: {
        altInput: true,
        altFormat: "j F, Y",
        dateFormat: "Y-m-d",
        locale: Russian,
      },
      fileRecords: [],
      fileRecordsForUpload: [], // maintain an upload queue
      fileRecordsForSubmit: [], // maintain an upload queue
    };
  },
  mounted() {
    this.getMerchants();
  },
  methods: {
    dateToTimestamp(val) {
      return moment(val).format("YYYY-MM-DD 00:00:00");
    },
    dateToTimestamp2(val) {
      return moment(val).unix();
    },
    async getBranches() {
      const response = await axiosIns.get("branches/getAll/by-vendorId", {
        params: {
          vendorId: this.getOffer.vendor.id,
        },
      });
      this.sortedBranches = response.data.data;
      if (this.sortedBranches.length === 0) {
        this.getOffer.branches = null;
      }
      if (this.getBranchesFirstTime) {
        this.getOffer.branches = [];
      }
      this.getBranchesFirstTime = true;
    },
    async getMerchants(search) {
      const response = await axiosIns
        .get("vendor/all-active", {
          params: {
            page: 1,
            size: 20,
            field: "name",
            fieldValue: search,
          },
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Ошибка`,
                icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                variant: "danger",
                text: error.response.data.errorMessage,
              },
            },
            {
              position: "top-right",
            }
          );
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
      this.sortedMerchants = response.data.data.vendors;
    },
    async uploadFiles() {
      for (var i = 0; i < this.fileRecordsForUpload.length; i++) {
        const formData = new FormData();
        formData.append("logo", this.fileRecordsForUpload[i].file);
        const response = await axiosIns.post("image/upload/offers", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.fileRecordsForSubmit.push(response.data.data.imageUrl);
      }
      this.$store.commit("setOfferImages", this.fileRecordsForSubmit);
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      );
    },
    async filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload = validFileRecords;
      this.uploadFiles();
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        const k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    async handleFileUpload() {},
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const date1 = this.dateToTimestamp(this.getOffer.finishDate);
          const date2 = this.dateToTimestamp2(date1);
          const offerImages = this.getOffer.offerImageList.map(function (item) {
            if (item.url) {
              return item.url;
            } else {
              return item;
            }
          });

          console.log(offerImages);

          const branches = this.getOffer.branches.map(function (item) {
            return item.id;
          });
          axiosIns
            .put("offer/edit", {
              vendorId: this.getOffer.vendor.id,
              branchIdList: branches,
              offerId: Number(this.$router.currentRoute.params.id),
              offerForCreateDto: {
                discountPercentAmount: this.getOffer.discount,
                titleRu: this.getOffer.titleRu,
                titleUz: this.getOffer.titleUz,
                titleEn: this.getOffer.titleEn,
                descriptionRu: this.getOffer.descriptionRu,
                descriptionUz: this.getOffer.descriptionUz,
                descriptionEn: this.getOffer.descriptionEn,
                dateExpired: date2,
                offerType: "CERTIFICATE",
                offerImages: offerImages,
              },
              offerState: this.getOffer.offerState,
              isGiftable: this.getOffer.isGiftable,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Предложение было успешно изменено!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "offers" });
            });
        }
      });
    },

    checkDiscount() {
      if (this.getOffer.discount > 100) {
        this.getOffer.discount = 100;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.my-file-agent .file-preview-wrapper .file-preview {
  width: calc(100% - 50px); /* 50px: width of the button */
}
.my-file-agent .file-preview-button {
  position: absolute;
  z-index: 11;
  width: 50px;
  height: 100%;
  right: 0;
  background: yellow;
  border: 2px solid red;
}
</style>
