var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-media',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('div',[_c('h5',[_vm._v("Изображения")]),_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"multiple":true,"sortable":false,"deletable":true,"accept":'image, .jpg, .png, .jpeg, .tiff',"meta":false,"maxSize":'5MB',"maxFiles":14,"helpText":'Выберите изображения',"errorText":{
             type: 'Недопустимый тип файла. Допустимы только изображения',
             size: 'Изображения не должны весить больше 5мб',
           }},on:{"select":function($event){return _vm.filesSelected($event)},"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)}},model:{value:(_vm.getOffer.filteredOfferImages),callback:function ($$v) {_vm.$set(_vm.getOffer, "filteredOfferImages", $$v)},expression:"getOffer.filteredOfferImages"}}),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])],1)]},proxy:true}])}),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Название Ru","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Ru","label-for":"titleRu"}},[_c('b-form-input',{attrs:{"id":"titleRu","state":errors.length > 0 ? false : null},model:{value:(_vm.getOffer.titleRu),callback:function ($$v) {_vm.$set(_vm.getOffer, "titleRu", $$v)},expression:"getOffer.titleRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Название Uz","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название Uz","label-for":"titleUz"}},[_c('b-form-input',{attrs:{"id":"titleUz","state":errors.length > 0 ? false : null},model:{value:(_vm.getOffer.titleUz),callback:function ($$v) {_vm.$set(_vm.getOffer, "titleUz", $$v)},expression:"getOffer.titleUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Название En","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название En","label-for":"titleEn"}},[_c('b-form-input',{attrs:{"id":"titleEn","state":errors.length > 0 ? false : null},model:{value:(_vm.getOffer.titleEn),callback:function ($$v) {_vm.$set(_vm.getOffer, "titleEn", $$v)},expression:"getOffer.titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание RU","label-for":"merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-ru","placeholder":"Описание","state":errors.length > 0 ? false : null,"rows":"7"},model:{value:(_vm.getOffer.descriptionRu),callback:function ($$v) {_vm.$set(_vm.getOffer, "descriptionRu", $$v)},expression:"getOffer.descriptionRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание UZ","label-for":"merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-uz","placeholder":"Описание","state":errors.length > 0 ? false : null,"rows":"7"},model:{value:(_vm.getOffer.descriptionUz),callback:function ($$v) {_vm.$set(_vm.getOffer, "descriptionUz", $$v)},expression:"getOffer.descriptionUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание EN","label-for":"merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"merch-desc-en","placeholder":"Описание","state":errors.length > 0 ? false : null,"rows":"7"},model:{value:(_vm.getOffer.descriptionEn),callback:function ($$v) {_vm.$set(_vm.getOffer, "descriptionEn", $$v)},expression:"getOffer.descriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Скидка","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Скидка","label-for":"discount"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"id":"discount","state":errors.length > 0 ? false : null,"type":"number"},on:{"input":_vm.checkDiscount},model:{value:(_vm.getOffer.discount),callback:function ($$v) {_vm.$set(_vm.getOffer, "discount", $$v)},expression:"getOffer.discount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.getOffer.finishDate)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Дата окончания","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Дата окончания","label-for":"dateExpired"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"new-offer-expired-date","placeholder":"Выберите дату окончания","state":errors.length > 0 ? false : null,"config":_vm.datePickerConfig},model:{value:(_vm.getOffer.finishDate),callback:function ($$v) {_vm.$set(_vm.getOffer, "finishDate", $$v)},expression:"getOffer.finishDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3169789281)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Статус","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Статус","label-for":"offerState"}},[_c('v-select',{attrs:{"id":"offerState","placeholder":"Выберите статус предложения","options":_vm.statusOptions,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
           var search = ref.search;
           var searching = ref.searching;
           var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.getOffer.offerState),callback:function ($$v) {_vm.$set(_vm.getOffer, "offerState", $$v)},expression:"getOffer.offerState"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Тип","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Тип","label-for":"offerType"}},[_c('v-select',{attrs:{"id":"offerType","placeholder":"Выберите тип статуса","options":_vm.offerTypes2,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
           var search = ref.search;
           var searching = ref.searching;
           var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.getOffer.offerType),callback:function ($$v) {_vm.$set(_vm.getOffer, "offerType", $$v)},expression:"getOffer.offerType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Мерчант","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Мерчант","label-for":"offerMerchant"}},[_c('v-select',{attrs:{"id":"offerMerchant","placeholder":"Выберите мерчант предложения","options":_vm.sortedMerchants,"label":"name","state":errors.length > 0 ? false : null},on:{"search":_vm.getMerchants,"input":_vm.getBranches},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
           var search = ref.search;
           var searching = ref.searching;
           var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.getOffer.vendor),callback:function ($$v) {_vm.$set(_vm.getOffer, "vendor", $$v)},expression:"getOffer.vendor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Филиалы","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
           var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Филиалы","label-for":"offerBranches"}},[_c('v-select',{attrs:{"id":"offerBranches","placeholder":"Выберите филиал предложения","label":"name","options":_vm.sortedBranches,"state":errors.length > 0 ? false : null,"multiple":""},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
           var search = ref.search;
           var searching = ref.searching;
           var loading = ref.loading;
return [_vm._v(" Пусто ")]}}],null,true),model:{value:(_vm.getOffer.branches),callback:function ($$v) {_vm.$set(_vm.getOffer, "branches", $$v)},expression:"getOffer.branches"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }