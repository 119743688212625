<template>
  <div>
    <div class="d-flex align-items-center mb-1">
      <a @click="$router.go(-1)" class="grey-color">
        <div class="d-flex align-items-center mr-2">
          <feather-icon icon="ArrowLeftIcon" size="20" class="mr-25" />
          <h6 class="mb-0">Назад</h6>
        </div>
      </a>
    </div>
    <h1 class="mb-2">Редактирование предложения</h1>

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="getOffer === undefined">
      <h4 class="alert-heading">
        Ошибка при получении данных этого предложения
      </h4>
      <div class="alert-body">
        Предложение с таким айди не существует. Проверьте
        <b-link class="alert-link" :to="{ name: 'offers' }">
          Список предложений
        </b-link>
        для других предложений.
      </div>
    </b-alert>

    <b-row>
      <b-col cols="12" md="12">
        <OfferEditTabInfoCard class="mb-" />
      </b-col>

      <b-col cols="12" md="12">
        <component :is="getOffer === undefined ? 'div' : 'b-card'">
          <offer-edit-tab-info />
        </component>
      </b-col>
    </b-row>

    <b-tabs v-if="getOffer" pills>
      <!-- Tab: Account -->
      <!-- <b-tab active>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Информация</span>
          </template>
          
        </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab>
          <template #title>
            <feather-icon icon="Share2Icon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Филиалы</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->

      <!-- Tab: Services -->
      <!-- <b-tab>
          <template #title>
            <feather-icon icon="ShoppingCartIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Товары/услуги</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->

      <!-- Tab: Promocodes -->
      <!-- <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">Купоны/сертификаты</span>
          </template>
          <merchant-edit-tab-services />
        </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BRow, BCol, BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import OfferEditTabInfo from "./OfferEditTabInfo.vue";
import OfferEditTabInfoCard from "./OfferEditTabInfoCard.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OfferEditTabInfo,
    OfferEditTabInfoCard,
  },
  computed: {
    ...mapGetters(["getOffer"]),
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("fetchOffer", this.$router.currentRoute.params.id);
  },
  methods: {},
};
</script>

<style></style>
